<template>
  <!-- <div class="statusbar" :style="{ height: heights }"></div> -->
  <router-view v-if="a" />
</template>
<script>
// @ is an alias to /src
// import "tailwindcss/tailwind.css"
import storeAction from "@/store/typed-actions";
import homeApi from "@/api/home";
import VueI18n from "./i18n/index";
export default {
  name: "Appa",
  components: {},
  data() {
    return {
      a: false,
    };
  },
  methods: {
    async webinfo() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      await homeApi
        .siteInfo()
        .then((data) => {
          this.$toast.clear();
          let banner = data.data.banner;
          storeAction.updateBannerImg(banner);
          storeAction.updateAboutTxt(data.data.about);
          storeAction.updateAbout_withdraw(data.data.about_withdraw)
          storeAction.updateTitle(data.data.site_title)
          storeAction.updatePartner(data.data.partner)
          storeAction.setCurrentMonetary_unit(data.data.monetary_unit);
          storeAction.setsessionStorage("out_time_pay", data.data.out_time_pay);
          storeAction.setsessionStorage("action_time", data.data.action_time);
          storeAction.setCurrentout_time_pay(data.data.out_time_pay);
          storeAction.updateCurrentaction_time(data.data.action_time);
          document.title = data.data.site_title;
          storeAction.setsessionStorage("site_title", data.data.site_title);
          storeAction.updateCurrentmobile_prefix(data.data.mobile_prefix);
          localStorage.setItem("mobile_prefix", data.data.mobile_prefix);
          localStorage.setItem("pay_blocks", data.data.pay_blocks);
          localStorage.setItem("pay_limit", data.data.pay_limit);
          localStorage.setItem("pay_usdt_rates", data.data.pay_usdt_rates);
          localStorage.setItem("pay_usdt", data.data.pay_usdt);
          localStorage.setItem("aboutTxt", data.data.about);
          localStorage.setItem(
            "nation_elect",
            data.data.nation_elect
              ? JSON.stringify(data.data.nation_elect[1])
              : "[]"
          );
          storeAction.setsessionStorage(
            "monetary_unit",
            data.data.monetary_unit
          );
          if (data.data.default_state == 0) {
            // 英文
            localStorage.setItem("locale", "en");
            VueI18n.global.locale.value = "en";
          } else if (data.data.default_state == 1) {
            // 中文
            localStorage.setItem("locale", "zh");
            VueI18n.global.locale.value = "zh";
          } else if (data.data.default_state == 2) {
            // 巴西-葡萄牙语言
            VueI18n.global.locale.value = "pe";
            localStorage.setItem("locale", "pe");
          } else if (data.data.default_state == 3) {
            //语言-越南-越南语
            VueI18n.global.locale.value = "yn";
            localStorage.setItem("locale", "yn");
          } else if (data.data.default_state == 4) {
            // 哥伦比亚-西班牙语言
            VueI18n.global.locale.value = "xby";
            localStorage.setItem("locale", "xby");
          } else if (data.data.default_state == 5) {
            // 土耳其语言
            VueI18n.global.locale.value = "teq";
            localStorage.setItem("locale", "teq");
          } else if (data.data.default_state == 6) {
            // 菲律宾语
            VueI18n.global.locale.value = "tl";
            localStorage.setItem("locale", "tl");
          } else if (data.data.default_state == 7) {
            // 印度英语
            VueI18n.global.locale.value = "in_en";
            localStorage.setItem("locale", "in_en");
          } else if (data.data.default_state == 8) {
            // 墨西哥
            VueI18n.global.locale.value = "es";
            localStorage.setItem("locale", "es");
          } else if (data.data.default_state == 9) {
            // 秘鲁
            VueI18n.global.locale.value = "es";
            localStorage.setItem("locale", "es");
          }
          else if (data.data.default_state == 10) {
            // 埃及
            VueI18n.global.locale.value = "ar";
            localStorage.setItem("locale", "ar");
          }
          else if (data.data.default_state == 11) {
            // 哥伦比亚
            VueI18n.global.locale.value = "es";
            localStorage.setItem("locale", "es");
          }
          else if (data.data.default_state == 12) {
            // 俄罗斯
            VueI18n.global.locale.value = "ru";
            localStorage.setItem("locale", "ru");
          }
          else if (data.data.default_state == 13) {
            // 阿拉伯
            VueI18n.global.locale.value = "ara";
            localStorage.setItem("locale", "ara");
          }
          else if (data.data.default_state == 14) {
            // 英文
            localStorage.setItem("locale", "en");
            VueI18n.global.locale.value = "en";
          }
          else if (data.data.default_state == 15) {
            // 智利
            VueI18n.global.locale.value = "cl";
            localStorage.setItem("locale", "cl");
          }
          this.a = true;
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
    this.webinfo();
  },
};
</script>



