const xby = {
  home: {
    title: "Buyer order-mall",
    chongzhi: "Recarga ",
    tixian: " Extracción ",
    yaoqing: "Invitar ",
    Aviso: "Aviso",
    Balance: "Balance",
    Hoy: "Hoy",
    Financiero: "Financiero",
    Congelar: " Congelar",
    MiembroNoticias: "Miembro-Noticias",
    Socio: "Socio",
    About: "About Us",
    aboutwithdrawal: "About withdrawal",
  },
  about: {
    title: "About Us  ",
    guanyutixian: "About withdrawal",
  },
  Recargar: {
    title: "Recargar",
    Introducir: "Introduzca la cantidad de recarga",
    tps: "* Cantidad mínima de recarga ",
    Selección: "Selección rápida de dinero",
    text: " Consejo: La cuenta de la plataforma del banco cambia en cualquier instante. En cada depósito, por favor, diríjase a la página en donde rellenar los datos para indicar el número de cuenta más actualizado. ¡Nosotros no nos hacemos responsables en caso de que usted deposite en una cuenta que ya haya expirado！",
  },
  RecargarAction: {
    title: "Recargar y presentar",
    Cantidad: "Cantidad de recarga",
    Información: "Información del receptor del pago",
    tps: "¡Tras completar la transferencia, por favor, asegúrese de colgar primero la captura de pantalla de la transferencia, y después presionar el botón de presentar! ",
    jietu: "Colgar captura de pantalla de transferencia",
    querenchongzhi: "Confirmar recarga",
  },
  Recolectar: {
    title: "Recolectar",
    Pedidos: "Pedidos recolectado",
    yongjin: "Comisión ",
    jinrishouru: "Ingresos de hoy",
    gerenshouru: " Ganancias personales",
    yiwancheng: "Pedidos completados",
    dongjie: " Pedidos bloqueados",
    weiwancheng: "Pedidos inacabados",
    dongjiejine: " Cantidad congelada",
    dangqianyue: "Balance actual",
    kaishi: "auto - recolec",
    tingzhi: " Detener recolección",
    tishi: " Aviso",
    Segundo: "Segundos",
    text: "En cuanto el usuario termine el pedido, la plataforma enviará simultáneamente la información del pedido al vendedor.  Si el usuario no paga el pedido al cabo de 60  unos minutos, el pedido se congelará para eludir la supervisión de la plataforma de compras online.  Una vez se congela el pedido, el dinero del pedido también se congela. Usted tiene que esperar a que el sistema los descongele horas más tarde. Por favor, tenga esto presente.",
  },
  Registro: {
    title: "Registro",
    daizhifu: "Pendiente",
    yijiesuan: "Completado",
    chulizhong: "Ajustes",
    yidongjie: "Congelado",
    meiyoujilu: "Sin registros",
    xianzaishouji: "Recolecta ahora",
    jiage: "Precio",
    yongjin: "Comisión",
    shouyi: "Ganancias",
    shengyutime: "Esperando",
    chakan: "Proceso de compra ",
    weizhifu: "Sin pagar",
    fukuan: "Pagado",
  },
  Mex: "",
  Confirmar: "Confirmar ",
  Conectando: "Conectando",
  fuzhiOK: "Copiado al tablero de pasta",
  Detalles: {
    title: "Detalles de pedido",
    yigong: "Robo total ",
    dingdan: " Pedido，",
    dangqian: "Ahora es",
    yiwancheng: " El pedido se ha completado.",
    dongjie: " El pedido está congelado.",
    dengdai: "Espera a que el comprador confirme el pedido  ",
    dongjies: "Congelación",
    zhuyao: "Tienda principal.",
    guanfang: " Garantías oficiales",
    zongshuliang: " Cantidad total",
    daigou: "Comisión.",
    shouyi: "Ganancias",
    fukuanzt: "Estado de pago",
    weizhifu: "Sin pagar",
    yizhifu: "Pagado",
    jiedong: " Hora para descongelar",
    zhifu: "Pagar",
    shuruzhifu: "Introduzca contraseña de pago",
    yanzheng: "Verificar contraseña de pago",
  },
  Pofile: {
    title: "Detalles de cuenta",
    yaoqingma: "Código de invitación",
    fuzhi: "Copiar enlace",
    Balance: "Balance",
    tixian: "Extracción",
    chongzhi: "Recargar",
    zongji: "Total",
    jinrong: "Financiero ",
    xiangqing: "Detalles",
    dongjie: "Congelar",
    geren: " Personal ",
    tuandui: "Registro de equipo",
    yaoqing: "Invitar amigo",
    xiaoxi: "Servicio",
    dizhi: "Dirección",
    guanyu: "Sobre nosotros",
    renwu: "Invitar tarea",
    anquan: " Seguridad",
    fuwu: " Centro de servicio",
  },
  alert: {
    title: "Consejos",
    quxiao: " Cancelar ",
    queding: "Determinar",
    acuerdo: "De acuerdo",
    fuzhi: "Copiar ",
    qianwang: "Vincular ahora",
  },
  withdrawal: {
    title: "Extracción",
    yue: " Balance actual",
    shouxufei: " Índice de extracción",
    dancitixian: "Tarifa mínima por una única extracción",
    shuru: " Introduzca la cantidad",
    dangqianjine: " Cantidad actual en la cuenta",
    suoyou: " Todas las extracciones",
    zhanghuming: "Nombre del titular",
    zhanghu: "Número de cuenta",
    dianhua: "Número de teléfono",
    tps: "Consejos",
    text: "1. Por favor, rellene con precisión la información acerca del banco, nosotros no nos hacemos responsables de los errores que usted pueda cometer a la hora de introducir la información y que puedan ocasionar pérdidas en los fondos；A las 2:24 en punto antes del día siguiente, la hora específica de llegada está sujeta al banco",
    queren: "Confirma la extracción",
    guanlian:
      "Por favor, vincula su tarjeta bancaria antes de realizar una extracción",
  },
  prsonal: {
    title: "Perfil",
    name: "Nombre",
    shouji: "Número de teléfono",
    yhk: "Tarjeta bancaria",
    tuichu: "Cerrar sesión",
    tianxie: "Vincular",
  },
  card: {
    title: " Vincule su tarjeta bancaria",
    zhanghuxinxi: "Información de cuenta",
    tps: "Por favor, asegúrese de que su número de cuenta es correcto, nosotros no nos hacemos responsables de lo que pudiere suceder en caso de error.",
    yhmc: "Nombre del banco",
    zhanghuming: "Nombre de usuario ",
    zhanghu: "Número de cuenta",
    shouji: "Número de teléfono",
    fenhang: "Nombre de sucursal bancaria",
    baocun: "Guardar",
    jianyi: "Consejos",
    text: " ¡Para obtener información sobre cómo vincular su tarjeta bancaria, por favor, contacte vía online servicio al cliente en caso de que necesite realizar modificaciones!",
    qyh: "Introduzca nombre del banco",
    qsryhm: "Introduzca nombre de usuario del banco",
    qzh: "Introduzca número de cuenta",
    qdh: "Número de teléfono",
    qfhmc: "Por favor, introduzca nombre de sucursal bancaria",
    qsrifcs: "Por favor, introduzca IFSC",
    youxiang: "Buzón de correo",
    plyx: "Por favor, introduzca el buzón de correo",
    jgbh: "Número de institución",
    pljgbh: "Introduzca el número de institución",
    gjj: "Fondo de Previsión",
    plgjj: "Por favor, introduzca",
    isbp: "ISBP",
    plisbp: "Por favor, introduzca ISBP",
    pixtype: "PIXType",
    plpixtype: "Por favor, introduzca PIXType",
    pix: "PIX",
    plpix: "Por favor, introduzca PIX",
    evp: "EVP",
    plevp: "Por favor, introduzca EVP",
  },
  team: {
    title: "Registro de equipo",
    Directo: "Directo",
    Secundario: "Secundario",
    Terciario: "Terciario",
    Comisión: "Comisión de hoy",
    zongyongjin: " Comisión total",
    shuzi: "Número",
    sudu: "Tarifa",
    jinricz: "Recarga de hoy",
    tixian: "Extracción total",
  },
  invitation: {
    title: "Invitar amigo",
    notitle: "Invite amigos y ganen más dinero juntos",
    notitles: " Usted puede realizar trabajo a tiempo parcial por teléfono",
    yqm: "Código de invitación",
    fuzhi: "Copy",
    xzyq: "Invitar ahora",
  },
  message: {
    title: "Servicio al cliente",
    go: "Contacto",
  },
  address: {
    title: "Gestión de dirección",
    xm: "Nombre",
    dianhua: "Teléfono",
    qtx: "Por favor, rellene",
    dizhixq: "Información sobre dirección de envío",
    baocun: "Guardar",
  },
  invitationTask: {
    title: "Invitar misión",
    zongren: "Número total de personas",
    gengxin:
      " Actualizar tarea: los usuarios que hayan recargado son usuarios válidos",
    yq: "Invitar",
    jige: "Miembros válidos",
    qianwang: " Ejecutar",
    renwujiangli: "Premios de tarea",
  },
  safe: {
    aqgl: "Gestión de seguridad",
    denglu: " Contraseña de inicio",
    zfmm: "Contraseña de pago",
    bh: "Protegiendo",
  },
  password: {
    title: "Contraseña de inicio",
    titles: "Contraseña de pago",
    shouji: "Número",
    yanzheng: "Código",
    fasong: "Enviar",
    xinmima: "Contraseña",
    pxinmima: "输入新密码",
  },
  record: {
    title: "Detalles del recibo",
    quanbu: "Todo ",
    chongzhi: "Recargar",
    tixian: " Extraer",
    dingdan: "Robar",
    tuandui: "Comisión",
    zongsu: "Número total",
    dangqian: "Número actual",
    kaishisj: "Hora de inicio-",
    jieshusj: "Hora de finalización",
  },
  login: {
    zhanghu: "Número de teléfono/ cuenta",
    mima: "Por favor, introduzca la contraseña",
    denglu: "Iniciar sesión",
    zhuce: "Registrarse ahora",
    wjmm: " He olvidado la contraseña",
  },
  regist: {
    touxiang: "Registro",
    yhm: " Nombre de usuario",
    mima: "Contraseña",
    zhifu: "Pago ",
    yq: "Invitar",
    pyhm: " Por favor, introduzca un nombre de usuario",
    ptel: " Por favor, introduzca un número de teléfono",
    shuru: " Introduzca contraseña",
    shurupay: "Introduzca 6 números arábigos",
    pyqm: " Código de invitación",
    plyqm: "Por favor, Código de invitación",
    zhuce: "Registrado",
    yijing: " ¿Ya tiene una cuenta?",
  },
  tabbars: {
    shouye: "Menú",
    chongzhi: "Recargar",
    shouji: "Recolectar",
    dingdan: "Pedir",
    wode: "Perfil",
  },
  zhaohuimima: "Please contact customer service for handling",
  yanzhengzhifu: "Verify payment password",
  huanying: "Bienvenido/a",
  yinhang: "Banco",
  yinhangbh: "Número do banco",
  beizhu: "Observação",
  zhifufs: "Método de pago",
  qxzzffs: "Por favor, seleccione el método de pago",
  usdt: {
    title: "canal de recarga USDT (TRC20)",
    tips1: "Seleccione la cantidad de recarga",
    rates: "Tasas de cambio",
    cashTips: "Ha seleccionado una cantidad de recarga de",
    usdtTips: "Debe utilizar USDT (TRC20) para pagar el ",
    customAmount: "Puede ingresar directamente la cantidad",
    importantTips: {
      one: "1. Por favor, transfiera la misma cantidad que se menciona arriba para USDT",
      two: "2. Por favor, complete la transferencia dentro de los 5 minutos, de lo contrario, el tiempo expirará y fallará.",
      three:
        "3. Si tiene algún problema, por favor, contacte a nuestros representantes de servicio al cliente para que le ayuden a resolverlo.",
    },
  },
  selectChannel: {
    title: "Seleccione un método de pago",
    cashChannel: "Canal de pago en efectivo",
    usdtChannel: "Canal de pago USDT (TRC20)",
    rewards: "Bonificación adicional del 5% en efectivo",
  },
};
export default xby;
