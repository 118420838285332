import { createI18n } from "vue-i18n";
import zh from "./config/zh";
import en from "./config/en";
import pe from "./config/pe";
import xby from "./config/xby";
import yn from "./config/yn";
import teq from "./config/teq";
import tl from "./config/tl";
import in_en from "./config/in_en";
import es from "./config/es";
import ar from "./config/ar";
import cl from "./config/cl";

console.log(createI18n);
const i18n = createI18n({
  legacy: false, // composition API
  globalInjection: true,
  locale: localStorage.getItem("locale") || "en",
  messages: {
    zh,
    en,
    pe,
    xby,
    yn,
    teq,
    tl,
    in_en,
    es,
    ar,
    cl,
  },
});
export default i18n;
