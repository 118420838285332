<template>
  <vue-seamless-scroll :data="memberInfo" :class-option="classOption" class="w-full">
    <div v-for="(item, index) in memberInfo" :key="index" class="flex justify-between items-center text-sm mb-3 py-1">
      <div class="flex justify-between items-center space-x-2">
        <div class="rounded-md bg-a-2 w-11 h-11 flex justify-center items-center">
          <span class="material-symbols-outlined text-a-1">
            {{ AuthList[item.auth] }}
          </span>
        </div>
        <div class="text-t-2 text-xs">
          <div class=" ">{{ item.nickname }}</div>
          <div class="text-b-3">{{ item.mark }}</div>
        </div>
      </div>
      <div class="text-xs text-a-1">{{ $t("Mex") }}{{ monetary_unit }}{{ item.quantity }}</div>
    </div>
  </vue-seamless-scroll>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll/src";
import storeAction from "@/store/typed-actions";
import userApi from "@/api/user";
export default {
  name: "Member",
  props: {},
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    memberInfo() {
      console.log(this.$store.state.memberInfo);
      return this.$store.state.memberInfo;
    },
    meberApishow() {
      console.log(this.$store.state.meberApishow);
      return this.$store.state.meberApishow;
    },
  },
  data() {
    return {
      // Info: "",
      AuthList: ["face_4", "face_6", "face_4", "face_6", "face_4", "face_6", "face_4", "face_4", "face_6", "face_4"],

      // AuthList: [
      //   require("@/assets/image/auth1.jpg"),
      //   require("@/assets/image/auth2.jpeg"),
      //   require("@/assets/image/auth3.jpeg"),
      //   require("@/assets/image/auth4.jpeg"),
      //   require("@/assets/image/auth5.jpeg"),
      //   require("@/assets/image/auth6.jpeg"),
      //   "",
      //   require("@/assets/image/auth.jpeg"),
      //   require("@/assets/image/auth7.jpeg"),
      // ],
    };
  },
  components: {
    vueSeamlessScroll,
  },
  methods: {
    info() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .heart()
        .then((data) => {
          console.log(data.data.profitNoticeList);
          for (let i = 0; i < data.data.profitNoticeList.length; i++) {
            data.data.profitNoticeList[i]["auth"] = data.data.profitNoticeList[
              i
            ].time.charAt(data.data.profitNoticeList[i].time.length - 1);
          }
          storeAction.updateMemberInfo(data.data.profitNoticeList);
          storeAction.updateMemberApishow(false);
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          console.log(err.message)
          this.$toast.fail(err.message);
        });
    },
    getRandomFace() {
      const values = ["face_4", "face_6"];
      const randomIndex = Math.floor(Math.random() * values.length);
      return values[randomIndex];
    }
  },
  mounted() {
    if (this.meberApishow) {
      this.info();
    }
  },
};
</script>

