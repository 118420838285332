const cl = {
  home: {
    title: "Centro comercial de pedidos del comprado",
    chongzhi: "recargar",
    tixian: "retirar",
    yaoqing: "invitar",
    Aviso: "advertir",
    Balance: "fondos",
    Hoy: "Este Dia",
    Financiero: "Finanzas",
    Congelar: "congelar",
    MiembroNoticias: "Información de miembro",
    Socio: "Compañero",
    About: "sobre nosotros",
    aboutwithdrawal: "Acerca del retiro",
  },
  about: {
    title: "sobre nosotros",
    guanyutixian: "Acerca del retiro",
  },
  Recargar: {
    title: "recargar",
    Introducir: "Por favor ingrese el monto de la recarga",
    tps: "Importe mínimo de recarga",
    Selección: "Selección rápida de cantidad",
    text: "Recordatorio: la cuenta de la plataforma bancaria se puede cambiar en cualquier momento. Para cada depósito, vaya a la página donde completó la información e indique el último número de cuenta. ¡No somos responsables si deposita en una cuenta que ha vencido!",
  },
  RecargarAction: {
    title: "Enviar recarga",
    Cantidad: "Monto de recarga",
    Información: "Información del beneficiario",
    tps: "Una vez completada la transferencia, asegúrese de cargar primero una captura de pantalla de la transferencia y luego haga clic en el botón Enviar.",
    jietu: "Subir captura de pantalla de transferencia",
    querenchongzhi: "Confirmar recarga",
  },
  Recolectar: {
    title: "recoger pedido",
    Pedidos: "comisión de pedido",
    yongjin: "comisión",
    jinrishouru: "ingresos hoy",
    gerenshouru: "renta personal",
    yiwancheng: "Pedido completado",
    dongjie: "orden de congelación",
    weiwancheng: "pedidos incompletos",
    dongjiejine: "cantidad congelada",
    dangqianyue: "saldo actual",
    kaishi: "empezar a coleccionar",
    tingzhi: "deja de coleccionar",
    tishi: "insinuación",
    Segundo: "segundo",
    text: "Después de que el usuario complete el pedido, la plataforma enviará la información del pedido al vendedor al mismo tiempo. Si el usuario no paga el pedido en un plazo determinado, el pedido quedará congelado para evitar la supervisión de la plataforma de compra online. Una vez que se congela una orden, el dinero de la orden también se congela. Hay que esperar unas horas a que el sistema los descongele. Por favor tenga esto en cuenta.",
  },
  Registro: {
    title: "Registro",
    daizhifu: "A pagar",
    yijiesuan: "Establecido",
    chulizhong: "Procesando",
    yidongjie: "congelado",
    meiyoujilu: "Sin registro",
    xianzaishouji: "recoger ahora",
    jiage: "precio",
    yongjin: "comisión",
    shouyi: "ingreso",
    shengyutime: "tiempo restante",
    chakan: "revisar orden",
    weizhifu: "no pagado",
    fukuan: "Pago",
  },
  Mex: "",
  Confirmar: "confirmar",
  Conectando: "Cargando",
  fuzhiOK: "copiado a la mesa de trabajo",
  Detalles: {
    title: "detalles del pedido",
    yigong: "Un total de ",
    dingdan: " pedidos",
    dangqian: "productos totales. Ahora es el número",
    yiwancheng: "Pedido completado",
    dongjie: "el pedido esta congelado",
    dengdai: "Esperando a que el comprador ",
    dongjies: " confirme el pedido",
    zhuyao: "tienda principal",
    guanfang: "garantía oficial",
    zongshuliang: "El número total",
    daigou: "Tarifa de compra",
    shouyi: "ingreso",
    fukuanzt: "estado de pago",
    weizhifu: "no pagado",
    yizhifu: "Pagado",
    jiedong: "tiempo de descongelación",
    zhifu: "pagar",
    shuruzhifu: "Por favor ingrese la contraseña de pago",
    yanzheng: "Verificar contraseña de pago",
  },
  Pofile: {
    title: "detalles de la cuenta",
    yaoqingma: "Código de invitación",
    fuzhi: "copiar link",
    Balance: "fondos",
    tixian: "retirar",
    chongzhi: "recargar",
    zongji: "total",
    jinrong: "Finanzas",
    xiangqing: "Detalles",
    dongjie: "congelar",
    geren: "personal",
    tuandui: "equipo",
    yaoqing: "invitar",
    xiaoxi: "Servicio al Cliente",
    dizhi: "Dirección",
    guanyu: "sobre nosotros",
    renwu: "Invitar tareas",
    anquan: "La seguridad",
    fuwu: "Centro de servicio",
  },
  alert: {
    title: "insinuación",
    quxiao: "Cancelar",
    queding: "Por supuesto",
    acuerdo: "aceptar",
    fuzhi: "Copiar",
    qianwang: "ir",
  },
  withdrawal: {
    title: "retirar",
    yue: "saldo actual",
    shouxufei: "Cargo por retiro",
    dancitixian: "Tarifa mínima de manejo para un solo retiro",
    shuru: "Ingrese la cantidad",
    dangqianjine: "Cantidad de retiro de cuenta corriente",
    suoyou: "extraer todo",
    zhanghuming: "nombre de la cuenta",
    zhanghu: "cuenta",
    dianhua: "número de teléfono",
    tps: "insinuación",
    text: "1. Complete la información bancaria con precisión, no seremos responsables de la pérdida de fondos causada por los errores que pueda cometer al ingresar la información; antes de las 2:24 del día siguiente, la hora específica de llegada está sujeta al banco",
    queren: "Confirmar retiro",
    guanlian: "Vincule su tarjeta bancaria antes de retirar dinero",
  },
  prsonal: {
    title: "personal",
    name: "Nombre",
    shouji: "número de teléfono",
    yhk: "tarjeta bancaria",
    tuichu: "desconectar",
    tianxie: "llenar",
  },
  card: {
    title: "Configuración de la tarjeta bancaria",
    zhanghuxinxi: "Información de la cuenta",
    tps: "Asegúrese de que su cuenta sea correcta, no somos responsables de ningún error.",
    yhmc: "Nombre del banco",
    zhanghuming: "nombre de usuario",
    zhanghu: "cuenta",
    shouji: "número de teléfono",
    fenhang: "nombre de la sucursal bancaria",
    baocun: "ahorrar",
    jianyi: "Sugerir",
    text: "Póngase en contacto con el servicio de atención al cliente en línea para conocer el método de enlace de la tarjeta bancaria, en caso de que necesite modificarlo.",
    qyh: "por favor elige",
    qsryhm: "Por favor ingrese el nombre de usuario del banco",
    qzh: "Por favor ingrese cuenta",
    qdh: "Por favor ingrese el teléfono",
    qfhmc: "Por favor ingrese el nombre de la sucursal",
    qsrifcs: "Por favor ingrese IFSC",
    youxiang: "Correo",
    plyx: "por favor ingrese su correo electrónico",
    jgbh: "Número de institución",
    pljgbh: "Por favor ingrese el número de la institución",
    gjj: "Fondo de Previsión",
    plgjj: "por favor escribe",
    isbp: "ISBP",
    plisbp: "Por favor ingrese ISBP",
    pixtype: "PIXType",
    plpixtype: "Por favor ingrese PIXType",
    pix: "PIX",
    plpix: "Por favor ingrese PIX",
    evp: "EVP",
    plevp: "Por favor ingrese EVP",
  },
  team: {
    title: "equipo",
    Directo: "directo",
    Secundario: "escuela intermedia",
    Terciario: "tercera",
    Comisión: "Tarifa de compra de hoy",
    zongyongjin: "comisión total",
    shuzi: "número",
    sudu: "velocidad",
    jinricz: "recarga hoy",
    tixian: "retiro completo",
  },
  invitation: {
    title: "Invitar a amigos",
    notitle: "Puedes hacer trabajos de medio tiempo desde tu teléfono",
    notitles: "Invita a tus amigos para ganar más dinero.",
    yqm: "Código de invitación",
    fuzhi: "Copiar",
    xzyq: "invitar ahora",
  },
  message: {
    title: "Servicio al Cliente",
    go: "conectar",
  },
  address: {
    title: "gestión de direcciones",
    xm: "Nombre",
    dianhua: "Teléfono",
    qtx: "Por favor complete",
    dizhixq: "Información de la dirección de envío",
    baocun: "ahorrar",
  },
  invitationTask: {
    title: "Invitar tareas",
    zongren: "personas totales",
    gengxin:
      "Tarea de actualización: el usuario recargado es un usuario válido",
    yq: "invitar",
    jige: "miembros activos",
    qianwang: "ir",
    renwujiangli: "recompensas de la misión",
  },
  safe: {
    aqgl: "administración de Seguridad",
    denglu: "contraseña de inicio de sesión",
    zfmm: "contraseña de pago",
    bh: "Proteger",
  },
  password: {
    title: "contraseña de inicio de sesión",
    titles: "contraseña de pago",
    shouji: "número de teléfono",
    yanzheng: "código de verificación",
    fasong: "enviar",
    xinmima: "Nueva contraseña",
    pxinmima: "Introduzca una nueva contraseña",
  },
  record: {
    title: "Detalles",
    quanbu: "todos",
    chongzhi: "recargar",
    tixian: "retirar",
    dingdan: "pedidos",
    tuandui: "equipo",
    zongsu: "total",
    dangqian: "datos actuales",
    kaishisj: "Tiempo de empezar",
    jieshusj: "Hora de finalización",
  },
  login: {
    zhanghu: "Por favor ingrese cuenta",
    mima: "Por favor, ingrese contraseña",
    denglu: "Iniciar sesión",
    zhuce: "Regístrate ahora",
    wjmm: "Olvidé mi contraseña",
  },
  regist: {
    touxiang: "avatar",
    yhm: "nombre de usuario",
    mima: "clave",
    zhifu: "contraseña de pago",
    yq: "invitar",
    pyhm: "por favor, ingrese un nombre de usuario",
    ptel: "Por favor ingrese el número de teléfono",
    shuru: "Por favor, ingrese contraseña",
    shurupay: "Ingrese el código de seguridad de 6 dígitos",
    pyqm: "Código de invitación",
    plyqm: "Por favor, introduzca el código de invitación",
    zhuce: "Registrarse",
    yijing: "Ya tienes una cuenta?",
  },
  tabbars: {
    shouye: "Inicio",
    chongzhi: "recargar",
    shouji: "recoger",
    dingdan: "pedidos",
    wode: "mía",
  },
  zhaohuimima:
    "Póngase en contacto con el servicio de atención al cliente para el procesamiento",
  yanzhengzhifu: "Verificar contraseña de pago",
  huanying: "bienvenidos",
  yinhang: "banco",
  yinhangbh: "RUT",
  beizhu: "Tipo de cuenta",
  zhifufs: "método de pago",
  qxzzffs: "Por favor seleccione el método de pago",
  usdt: {
    title: "canal de recarga USDT (TRC20)",
    tips1: "Seleccione la cantidad de recarga",
    rates: "Tasas de cambio",
    cashTips: "Ha seleccionado una cantidad de recarga de",
    usdtTips: "Debe utilizar USDT (TRC20) para pagar el ",
    customAmount: "Puede ingresar directamente la cantidad",
    importantTips: {
      one: "1. Por favor, transfiera la misma cantidad que se menciona arriba para USDT",
      two: "2. Por favor, complete la transferencia dentro de los 5 minutos, de lo contrario, el tiempo expirará y fallará.",
      three:
        "3. Si tiene algún problema, por favor, contacte a nuestros representantes de servicio al cliente para que le ayuden a resolverlo.",
    },
  },
  selectChannel: {
    title: "Seleccione un método de pago",
    cashChannel: "Canal de pago en efectivo",
    usdtChannel: "Canal de pago USDT (TRC20)",
    rewards: "Bonificación adicional del 5% en efectivo",
  },
};
export default cl;
